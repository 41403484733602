<template>
  <v-form
    ref="formNovaSenha"
    v-model="valid"
    lazy-validation
    @keyup.native.enter="emailSenha"
    width="100vw"
    height="100vh"
  >
    <v-row align="center" justify="center">
      <v-col md="7" sm="12">
        <v-btn
          color="login_green"
          @click="toLogin"
          :loading="loading"
          text
          class="ma-0 pa-0 mb-12"
        >
          <v-icon>mdi-arrow-left-thin</v-icon> Login
        </v-btn>

        <h6 class="text_dark_grey mb-12">Favor inserir uma nova senha.</h6>

        <v-row>
          <v-col>
            <v-text-field
              v-model="senha"
              label="Senha"
              :rules="rules.password"
              :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass1 ? 'text' : 'password'"
              @click:append="showPass1 = !showPass1"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="confirmacao_senha"
              label="Repita a senha"
              :rules="passwordConfirmationRule"
              :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass2 ? 'text' : 'password'"
              @click:append="showPass2 = !showPass2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-btn
              color="login_green"
              @click="novaSenha"
              :loading="loading"
              large
              rounded
              width="100px"
              class="mt-8 white--text"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '@/services/api'
import { email } from '@/methods/validationRules'

export default {
  name: 'NovaSenhaForm',
  props: {
    token: {
      type: String,
      default: null
    }
  },
  data: () => ({
    valid: false,
    showPass1: false,
    showPass2: false,
    rules: {
      required: [(v) => !!v || 'Campo obrigatório'],
      email: [email, [(v) => !!v || 'Campo obrigatório']],
      password: [
        [(v) => !!v || 'Campo obrigatório'],
        (v) => v.length >= 8 || 'Mínimo de 8 caracteres'
      ]
    },
    loading: false,
    senha: '',
    confirmacao_senha: ''
  }),
  computed: {
    passwordConfirmationRule () {
      return [
        [(v) => !!v || 'Campo obrigatório'],
        (v) => v === this.senha || 'As senhas não combinam',
        (v) => v.length >= 8 || 'Mínimo de 8 caracteres'
      ]
    }
  },
  methods: {
    async novaSenha () {
      if (this.$refs.formNovaSenha.validate()) {
        this.loading = true
        await api.post('api/password/reset', {
          password: this.senha,
          password_confirmation: this.confirmacao_senha,
          token: this.token
        })
          .then(resp => {
            this.$toastr.s(resp.data.message)
            this.toLogin()
          })
          .catch(err => {
            this.$toastr.e(err.response.data.message)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    toLogin () {
      const url = window.location.href
      window.history.replaceState({}, document.title, url.split('/esqueci-minha-senha')[0])
      this.$emit('voltar')
    }
  }
}
</script>
