<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @keyup.native.enter="login"
    width="100vw"
    height="100vh"
  >
    <v-row align="center" justify="center">
      <v-col md="7" sm="12">
        <h3 class="mb-16 hidden-lg-and-down">
          Faça mais e melhor, com a nossa plataforma de gestão.
        </h3>
        <h4 class="mb-16" v-if="$vuetify.breakpoint.lg">
          Faça mais e melhor, com a nossa plataforma de gestão.
        </h4>

        <h5 class="primary--text bold" v-if="$vuetify.breakpoint.md">
          Acesse sua conta
        </h5>
        <h4 class="primary--text bold" v-else>Acesse sua conta</h4>
        <h6 class="text_dark_grey mb-12">Digite seu login para entrar</h6>

        <v-row>
          <v-col>
            <v-text-field
              v-model="username"
              label="Email"
              required
              :rules="[rules.required, rules.email]"
              validate-on-blur
              outlined
              color="login_green"
              persistent-placeholder
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              label="Senha"
              required
              :rules="[rules.required, rules.min]"
              validate-on-blur
              :type="showPass ? 'text' : 'password'"
              outlined
              @click:append="showPass = !showPass"
              color="login_green"
              persistent-placeholder
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-btn
              color="login_green"
              @click="login"
              :loading="loading"
              large
              rounded
              width="100px"
              class="mt-8 white--text"
            >
              Entrar
            </v-btn>
          </v-col>
          <v-col class="ma-0 pa-0" align="end">
            <v-btn
              color="login_green"
              @click="$emit('esqueciMinhaSenha')"
              :disabled="loading"
              text
              class="ma-0 pa-0 mt-8"
            >
              Esqueci minha senha
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import auth from '@/services/auth'

export default {
  name: 'LoginForm',
  data: () => ({
    valid: false,
    rules: {
      required: (v) => !!v || 'Campo obrigatório',
      min: (v) => v.length >= 8 || 'Mínimo de 8 caracteres',
      email: (v) => /.+@.+\..+/.test(v) || 'Informe um e-mail válido'
    },
    username: '',
    password: '',
    loading: false,
    showPass: false
  }),
  methods: {
    async login () {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          await auth
            .login({
              username: this.username,
              password: this.password
            })
            .then((res) => {
              if (res.status !== 200) {
                this.hasError()
                return
              }
              this.$router.go()
            })
        } catch {
          this.hasError()
        }
      } else {
        this.hasError()
      }
    },
    hasError () {
      this.loading = false
      this.$toastr.e(
        'Ocorreu um problema ao tentar logar. Por favor revise os dados informados!'
      )
    }
  }
}
</script>
