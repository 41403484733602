<template>
  <v-form
    ref="formEsqueciMinhaSenha"
    v-model="valid"
    lazy-validation
    @keyup.native.enter="emailSenha"
    width="100vw"
    height="100vh"
  >
    <v-row align="center" justify="center">
      <v-col md="7" sm="12">
        <v-btn
          color="login_green"
          @click="$emit('voltar')"
          :loading="loading"
          text
          class="ma-0 pa-0 mb-12"
        >
          <v-icon>mdi-arrow-left-thin</v-icon> Voltar
        </v-btn>

        <h6 class="text_dark_grey mb-12">Informe seu e-mail para recebimento de mensagem para redefinição de sua senha.</h6>

        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              label="Email"
              required
              :rules="[rules.required, rules.email]"
              validate-on-blur
              outlined
              color="login_green"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-btn
              color="login_green"
              @click="emailSenha"
              :loading="loading"
              large
              rounded
              width="100px"
              class="mt-8 white--text"
            >
              Enviar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'EsqueciMinhaSenhaForm',
  data: () => ({
    valid: false,
    rules: {
      required: (v) => !!v || 'Campo obrigatório',
      email: (v) => /.+@.+\..+/.test(v) || 'Informe um e-mail válido'
    },
    email: '',
    loading: false
  }),
  methods: {
    async emailSenha () {
      if (this.$refs.formEsqueciMinhaSenha.validate()) {
        this.loading = true
        await api.post('api/password/create', {
          email: this.email
        })
          .then(resp => {
            this.$toastr.s(resp.data.message)
            this.$emit('voltar')
          })
          .catch(err => {
            this.$toastr.e(err.response.data.message)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
