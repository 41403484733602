<template>

    <v-row align="center" justify="center">
      <v-col md="6" v-show="$vuetify.breakpoint.mdAndUp" class="pb-0">
        <v-img aspect-ratio width="100vw" height="100%" :src="require('@/assets/images/login-page_2.png')" />
      </v-col>
      <v-col sm="12" md="6">
        <LoginForm v-if="isLoginForm" @esqueciMinhaSenha="changePage(2)" ></LoginForm>
        <EsqueciMinhaSenhaForm v-if="isEsqueciMinhaSenhaForm" @voltar="changePage(1)" ></EsqueciMinhaSenhaForm>
        <NovaSenhaForm v-if="isNovaSenhaForm" @voltar="changePage(1)" :token="token" ></NovaSenhaForm>
      </v-col>
    </v-row>

</template>

<script>
import LoginForm from '@/components/Login/Login'
import EsqueciMinhaSenhaForm from '@/components/Login/EsqueciMinhaSenha'
import NovaSenhaForm from '@/components/Login/NovaSenha'

export default {
  name: 'Login',
  components: {
    LoginForm,
    EsqueciMinhaSenhaForm,
    NovaSenhaForm
  },
  data: () => ({
    page: 1,
    token: null
  }),
  created () {
    if (this.$route.params.token) {
      this.token = this.$route.params.token
      this.changePage(3)
    }
  },
  computed: {
    isLoginForm () {
      return this.page === 1
    },
    isEsqueciMinhaSenhaForm () {
      return this.page === 2
    },
    isNovaSenhaForm () {
      return this.page === 3
    }
  },
  methods: {
    changePage (page) {
      this.page = page
    }
  }
}
</script>
